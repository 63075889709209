.irriwatch-graphs-container {
  width: -webkit-fill-available;
  // height: -webkit-fill-available !important;
  margin: 2rem !important;
  background-color: #05342f !important;
  border-radius: 12px;
  padding: 1rem;

  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: unset;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-left: -1rem;
    position: relative;
  }
  .card-title {
    font-size: 0.78rem;
    color: #c4fff9;
    margin: 0;
    white-space: break-spaces;
    max-width: 245px;
    text-overflow: ellipsis;
  }
  .date-range {
    /* text-align: left; */
    position: absolute;
    right: 195px;
    letter-spacing: 0px;
    color: #46aaa0;
    opacity: 1;
    text-wrap-mode: nowrap;
  }

  .titles {
    display: flex;
    align-items: center;
    gap: 700px;
  }
  .card-subtitle {
    color: #46aaa0;
    margin: 0;
  }
  [dir="rtl"] {
    .titles {
      gap: 800px;
    }
  }

  @media (max-width: 767px) {
    .titles {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }

  @media (max-width: 767px) {
    .date-range {
      text-align: left;
      position: absolute;
      /* top: 39px; */
      margin-top: 56px;
      /* margin: 0; */
      inset-inline-start: 143px;
      letter-spacing: 0px;
      color: #46aaa0;
      opacity: 1;
      text-wrap-mode: nowrap;
    }
    [dir="rtl"] .date-range {
      position: absolute !important;
      /* top: 39px; */
      margin-top: 40px !important;
      /* margin: 0; */
      inset-inline-start: 40px !important;
      letter-spacing: 0px !important;
      color: #46aaa0;
      opacity: 1 !important;
      text-wrap-mode: nowrap !important;
    }
  }

  .calendar-button {
    background-color: #004e47;
    justify-content: right;
    box-shadow: 0 4px 6px rgba(96, 96, 96, 0.1);
    color: #c4fff9;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    gap: 0.5rem; /* Space between the icon and the text */
    display: flex;
    align-items: right;
  }

  //   .calendar-button:hover {
  //     background-color: #00675B;
  //   }
  .calendar-icon {
    width: 1rem; /* Adjust the size as needed */
    height: 1rem;
  }
  .calendar-position {
    position: absolute;
    inset-inline-start: 80rem;
    .Calendar-Wrapper {
      position: absolute;
      padding-top: 20px;
      z-index: 5;

      .date-picker-container {
        width: 15rem;
        background-color: $light-container-background !important;
      }

      .triangle {
        position: absolute;
        width: 0;
        height: 0;
        top: 0.5em;
        left: 12.5em;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #196a63;
        z-index: 1000 !important;
      }
    }
  }
  .graph-card {
    background-color: #02312c;
    border-radius: 10px;
    padding: 1rem;
    margin-top: 1rem;
    min-height: 65vh; /* Set minimum height */
    display: flex;
    flex-direction: column;

    .graph-card-title {
      font-size: 0.78rem;
      color: #c4fff9;
      margin-bottom: 2rem;
    }

    .graph-card-description {
      font-size: 0.78rem;
      color: #46aaa0;
      // margin-bottom: 1rem;
      text-align: left;
      word-wrap: break-word;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1; /* Allow content to grow */
      position: relative; /* For legend positioning */
      padding-bottom: 5rem; /* Add padding at the bottom */

      /* Adjust the graph container */
      > div {
        width: 65vw;
        height: calc(50vh - 2rem) !important; /* Subtract padding from height */
      }
      .weather-graph {
        width: 65vw;
        height: 35vh !important;
      }
      .weather-graph.top {
        width: 65vw;
        height: 35vh !important;
        margin-bottom: -8rem;
      }
    }
    .custom-legend {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 16px;
      gap: 16px;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      margin-top: -75px;
      .legend-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .legend-color {
          width: 16px;
          height: 16px;
          // border-radius: 50%;
          display: inline-block;
        }

        .legend-label {
          font-size: 12px;
          color: #46aaa0;
        }
      }
    }
  }

  .antv-legend {
    position: absolute;
    bottom: 0; /* Position at the bottom */
    right: 0;
    padding: 3rem; /* Add padding around legend */
  }
}
[dir="rtl"] .irriwatch-graphs-container .graph-card .graph-card-description {
  text-align: right;
}
[dir="rtl"] .irriwatch-graphs-container .card-header .date-range {
  margin-left: unset;
  position: relative;
  right: 24rem;
  text-wrap-mode: nowrap;
}
@media (max-width: 1280px) {
  [dir="rtl"] .irriwatch-graphs-container .card-header .date-range {
    margin-left: unset;
    position: relative;
    right: 18rem;
    text-wrap-mode: nowrap;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  [dir="rtl"] .irriwatch-graphs-container .card-header .date-range {
    margin-left: unset;
    position: relative;
    right: 10rem;
    text-wrap-mode: nowrap;
  }
}
@media (max-width: 767px) {
  [dir="rtl"] .irriwatch-graphs-container .card-header .date-range {
    margin-left: unset;
    top: 12px;
    position: relative;
    right: 4.5rem !important;
    text-wrap-mode: nowrap;
  }
  [dir="rtl"]
    .irriwatch-graphs-container
    .card-header
    [class*="Dropdown_dropdown"] {
    position: relative;
    //right: -1.5rem !important;
  }
  [dir="rtl"]
    .irriwatch-graphs-container
    .card-header
    [class*="Dropdown_dropdownMenu"] {
    position: relative;
    min-width: 7rem;
  }
}

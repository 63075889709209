.Map-Button-Container {
  .Button-Container {
    // position: relative;
    display: flex;
  }

  .map-view-button {
    position: absolute;
    inset-inline-start: 0.5rem;
    background-color: #03403a;
    background-image: url("../../assets/icons/svg/sidebar/inactive/map.svg");
    background-repeat: no-repeat;
    background-position: center;
    //background-size: 2.2em;
    background-size: 21px;
    // width: 3rem;
    // height: 3rem;
    width: 42px;
    height: 42px;
    border: none;
    outline: none;
    border-radius: 5px;

    &:hover {
      background-image: url("../../assets/icons/svg/sidebar/active/map.svg");
      background-color: #73e4cc;
    }
  }

  .map-view-button:hover {
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 1.2em;
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      border-right: 7px solid #003a35cc;
      inset-inline-start: 3rem;
    }
  }

  .List {
    ul {
      list-style-type: none;
      column-count: 2;
      column-gap: 10px;
      display: flex;
      flex-wrap: wrap;
      margin-inline-start: -28px;
      margin-top: 13px;
    }

    background-color: #065a53;
    width: 170px;
    height: 160px;
    border-radius: 8px;
    position: absolute;
    justify-content: space-between;
    top: 1;
    z-index: 100;
    bottom: 2rem;
    inset-inline-start: 0.7rem;
    overflow: auto;

    // right: 8px;
    .Content {
      &:hover {
        border-radius: 20px;
        background-color: #73e4cc;
        cursor: pointer;

        p {
          color: #065a53 !important;
        }
      }

      flex-wrap: wrap;
      display: flex;
      //width: 64px;
      height: 25px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      margin: 3px;
      padding: 0 10px;

      p {
        color: #73e4cc;
        text-align: center;
        font-weight: 400;
        margin-top: 5px;
        //font-size: 0.64em;
        font-size: calc(
          10px + (12 - 10) * ((100vw - 320px) / (2800 - 320))
        ) !important;
      }
    }
  }

  .Container {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: scroll;
  }

  .Button {
    &:hover {
      .Circle-Container {
        border-radius: 5px;
        cursor: pointer;
        background-color: #73e4cc;
      }

      .content {
        color: #03403a;
      }

      .expanded {
        p {
          color: #03403a;
        }
      }

      .Circle-Container:not(.expanded) {
        &:hover::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-bottom: 7px solid transparent;
          border-top: 7px solid transparent;
          border-right: 7px solid #003a35cc;
          top: 1em;
          bottom: 0.4rem;
          inset-inline-start: 50px;
        }
      }

      .selected:not(.expanded) {
        &:hover::after {
          inset-inline-start: 100px;
        }
      }
    }

    width: 42px;
    background-color: #03403a;
    flex: 1;
    position: absolute;
    top: 1;
    bottom: 0.4rem;
    inset-inline-start: 11px;
    // right: 8px;
    transition: all 0.2s ease-out;
    border-radius: 5px;
  }

  .Farm-Button {
    &:hover {
      border-radius: 8px;
      cursor: pointer;
      background-color: #73e4cc;

      p {
        color: #03403a;
      }
    }

    height: 40px;
    background-color: #03403a;
    flex: 1 1;
    position: absolute;
    top: 10em;
    // bottom: 1em;
    left: 0.4rem;
    right: 1;
    transition: all 0.2s ease-out;
    border-radius: 8px;
  }

  .Farm-Content {
    p {
      margin-top: 15px;
    }

    background-color: #003a35cc;
    border-radius: 3px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10.7em;
    height: 30px;
    // width: 100px;
    inset-inline-start: 3.9rem;
    padding: 5px;
  }

  .Circle-Container {
    display: flex;
    flex: 1;
    //height: 100%;
    //width: 100%;
    min-width: 42px;
    justify-content: center;
    align-items: center;

    svg {
      width: 25px;
    }

    .Plot-Content {
      p {
        margin-top: 15px;
      }

      color: #73e4cc;
      background-color: #003a35cc;
      border-radius: 3px;
      flex: 1 1;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 30px;
      padding-inline: 10px;
      font-size: 1.2em;
      top: 0.2em;
      z-index: 1001;
      inset-inline-start: 3.5rem;
    }

    p {
      color: #73e4cc;
      text-align: center;
      font-weight: 400;
      //font-size: 0.64em;
      font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (2800 - 320)));
      margin-top: 8%;
    }

    flex-direction: row;
  }

  .selected {
    .Plot-Content {
      inset-inline-start: 6.6rem;
    }
  }
}

[dir="rtl"] {
  .Map-Button-Container .Circle-Container p {
    font-size: calc(10px + (13 - 10) * ((100vw - 320px) / (2800 - 320)));
  }

  .Circle-Container:not(.expanded) {
    &:hover::after {
      border-right: none !important;
      border-left: 7px solid #003a35cc;
    }
  }
}

@media (max-width: 768px) {
  .Button .Circle-Container:not(.expanded)::after {
    display: none;
  }
  .Map-Button-Container .Button:hover .Circle-Container:not(.expanded) {
    background-color: #03403a;
  }
  .Map-Button-Container .Circle-Container .Plot-Content {
    display: none;
    &::after {
      display: none;
    }
  }
}

body {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600; /* Equivalent to SemiBold */
}

* {
  font-family: inherit; /* Ensure all child elements use the same font-family */
}

.side-panel-Irriwatch::-webkit-scrollbar {
  // height: 100% !important;
  width: 10px !important;
  display: block !important ;
}

/* Track */
.side-panel-Irriwatch::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

/* Handle */
.side-panel-Irriwatch::-webkit-scrollbar-thumb {
  background: #256f68;
  border-radius: 10px;
  width: 2px !important;
  cursor: pointer !important;
}

/* Handle on hover */
.side-panel-Irriwatch::-webkit-scrollbar-thumb:hover {
  background: #348c83;
}

.side-panel-Irriwatch::-webkit-scrollbar {
  display: block !important;
}

.screen-navigator-button {
  bottom: 4em !important;
}

.plotSelector-position .Map-Button-Container .map-view-button {
  display: none;
}
.plotSelector-position .Map-Button-Container .List {
  z-index: 10000;
  width: 215px;
}
.plotSelector-position .Map-Button-Container .List .Content {
  text-wrap-mode: nowrap;
  font-family: 'nunito_semibold';
}

.plotSelector-position .Map-Button-Container .Button:hover .content{
  font-family: 'nunito_semibold';
} 
.plotSelector-position .Map-Button-Container .Circle-Container p{
  font-family: 'nunito_semibold';
}
.side-panel-Irriwatch.plot-view {
  left: -6rem;
  opacity: 0.96;
}
.side-panel-Irriwatch {
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  font: normal normal SemiBold Nunito Sans;
  background: hsl(174, 91%, 13%);
  opacity: 0.95;
  width: 23rem;
  padding: 1.9rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  // @media(max-width: 1400px ){
  //     width: 21%;
  // }

  .separator {
    height: 1px;
    background-color: #46aaa0;
    width: 104%;
    position: absolute;
    top: -20px;
    left: -5px;
    transform: translateY(-50%);
    position: relative;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-family: "nunito_semibold";
    // margin-bottom: 20px;

    .DatesContainer {
      display: flex;
      flex-direction: row;
      padding-top: 1rem;
      gap: 4.5rem;
      .dropdown-list-container {
        display: flex;
        .search-drop-down-list {
          margin-bottom: 0;
          .value-label {
            font-size: 13px;
            width: 2.85em !important;
            background: none !important;
            box-shadow: -3px -3px 6px #045850, 3px 4px 8px #022b27 !important;
          }
        }
      }
      .search-drop-down-list .value-label {
        font-size: 13px;
        height: 2.85em !important;
        background: none !important;
        box-shadow: -3px -3px 6px #045850, 3px 4px 8px #022b27 !important;
      }
      .search-drop-down-list .value-label:hover {
        background: #73e4cc 0% 0% no-repeat padding-box !important;
      }
      .search-drop-down-list .form-multi-select-search {
        left: 0 !important;
      }
      .search-drop-down-list .form-multi-select-selection::after {
        margin-inline-start: 20px !important;
      }
      .search-drop-down-list .form-multi-select-option.selected {
        border-inline-start: 5px solid #73e4cc !important;
      }
    }
    .date-time-picker-wrapper {
      .date-time-button {
        background-color: transparent;
        border: 2px solid transparent;
        outline: none !important;
        color: #73e4cc;
        font-size: 13px;
        padding-block: 7px;
        box-shadow: -3px -3px 6px #045850, 3px 4px 8px #022b27 !important;
        display: flex;
        flex-direction: row;
        width: 8rem;
        align-items: center;
        justify-content: center;
        font-family: "nunito_semibold";
        .clock-icon {
          display: flex;

          svg {
            transform: scale(0.75);

            #clock {
              fill: #73e4cc;
            }
          }
        }

        &:hover {
          background: #73e4cc 0% 0% no-repeat padding-box;
          border: 2px solid #c4fff9 !important;
          border-radius: 5px !important;
          color: #0a4a45;
        }
      }
      .date-time-button.plot-view {
        width: 8rem;
        margin-left: -3rem;
      }
      .date-picker-container .nice-dates-navigation_previous:before {
        border-right: 8px solid #73e4cc !important;
      }
      .date-picker-container .nice-dates-navigation_next:before {
        border-left: 8px solid #73e4cc !important;
      }
      .date-picker-container .nice-dates-navigation_current {
        color: #73e4cc !important;
      }
      .date-picker-container .nice-dates-day_date {
        color: #c4fff9 !important;
      }
      .date-picker-container .-today:not(.-selected) {
        border: 2px solid #46aaa080 !important;
        color: #c4fff9 !important;
        opacity: 0.3 !important;
      }
      .nice-dates-day.-disabled {
        color: #c4fff9 !important;
        opacity: 0.3 !important;
      }
      .date-picker-container .nice-dates-day.-selected * {
        color: #165f59 !important;
      }
    }
    .date-time-content {
      position: absolute;
      top: 42px !important;
      left: -11rem !important;
    }
    .date-time-content.farm-view {
      left: 0rem !important; /* Adjust value as needed */
    }
    .date-time-content.plot-view {
      left: -10rem !important; /* Adjust value as needed */
    }
    h3 {
      font-size: 13px;
      // font-size: 18px;
      font-weight: bold;
      margin: 0;
      color: #c4fff9;
    }
    .subtitle {
      font-size: 12px;
      // font-size: 18px;
      font-weight: bold;
      margin: 0;
      color: #c4fff9;
    }

    .calendar-button {
      background-color: #004e47;
      justify-content: right;
      box-shadow: 0 4px 6px rgba(96, 96, 96, 0.1);
      color: #c4fff9;
      border: none;
      border-radius: 5px;
      padding: 0.5rem 1rem;
      font-weight: bold;
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;
      gap: 0.5rem; /* Space between the icon and the text */
      display: flex;
      align-items: right;
    }
    .calendar-icon {
      width: 1rem; /* Adjust the size as needed */
      height: 1rem;
    }
    .calendar-position {
      position: absolute;
      inset-inline-start: 80rem;
      .Calendar-Wrapper {
        position: absolute;
        padding-top: 20px;
        z-index: 5;

        .date-picker-container {
          width: 15rem;
          background-color: $light-container-background !important;
        }

        .triangle {
          position: absolute;
          width: 0;
          height: 0;
          top: 0.5em;
          left: 12.5em;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-bottom: 12px solid #196a63;
          z-index: 1000 !important;
        }
      }
    }
  }
  .description-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space between the description and dropdown */
    align-items: left; /* Vertically align items in the center */
    margin-bottom: 5px;
    word-break: normal;
    .map-layers {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      padding-bottom: 0.5rem;
    }
    .map-layers-text {
      font-size: 13px;
      color: #c4fff9;
      margin-top: -8px;
    }
    .filter-buttons-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 10px;
      [class*="Dropdown_dropdownToggle"] {
        font-size: 13px !important;
        background-color: #03403a;
        box-shadow: -3px -3px 6px #045850, 3px 4px 8px #022b27 !important;
        color: #73e4cc !important;
        height: 2.85em;
        width: 12rem;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
      .dropdown-menu {
        width: 12rem;
      }
      [class*="Dropdown_dropdownToggle"]:hover {
        background: #73e4cc 0% 0% no-repeat padding-box;
        border: 2px solid #c4fff9 !important;
        border-radius: 5px !important;
        color: #0a4a45 !important;
      }
      [class*="Dropdown_dropdownItem"]:hover {
        text-transform: none;
        background-color: #39958d;
      }

      [class*="Dropdown_selected"] {
        text-transform: none;
      }

      [class*="Dropdown_arrow"] svg {
        display: none;
      }
      .help-button-wrapper {
        margin-inline-start: 5px;
      }

      .index-selector:not(.selected):hover {
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 100%;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          border-bottom: 7px solid #003a35cc;
          inset-inline-start: 30%;
        }

        .index-tooltip {
          background-color: #003a35cc;
          border-radius: 3px;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 115%;
          height: 30px;
          min-width: 100px;
          white-space: nowrap;
          padding-inline: 5px;
          inset-inline-start: 10% !important;
          color: #73e4cc;
        }
      }

      .drop-down-selector-container {
        width: 7.9em;
        height: 3em !important;
        border-radius: 5px;
        background-color: #03403a;
        border: none;
        outline: none !important;
        color: #73e4cc;
        box-shadow: 3px 3px 6px #001a17a2 !important;
      }

      .drop-down-selector-container:hover {
        background-color: #73e4cc;
        border: 2px solid #c4fff9;
        .current-choice {
          color: #165f59 !important;
        }

        .current-choice {
          &:hover::before {
            content: url("../../assets/icons/svg/sidebar/active/vegetationIndexactive.svg");
            padding-top: 5px;
            padding-inline-end: 5px;
          }
        }
      }

      .index-selector {
        margin-inline-start: 5px;

        .triangle {
          top: 100%;
          left: 40%;
        }

        .choices-list {
          top: 120%;
          inset-inline-start: -112% !important;
          width: 15em !important;
          max-height: 250px;
          overflow-y: auto;
          z-index: 1000;
        }

        .drop-down-selector-container {
          min-width: 7em !important;
          padding-inline: 5px !important;
          width: inherit;

          .current-choice {
            &::before {
              content: url("../../assets/icons/svg/sidebar/inactive/vegetationIndexInactive.svg");
              padding-top: 5px;
              padding-inline-end: 5px;
            }
          }
        }
      }

      .index-selector.ele {
        .current-choice {
          &::before {
            content: url("../../assets/icons/svg/VegetationIndex/elevDef.svg");
            padding-top: 5px;
            padding-inline-end: 5px;
          }
        }
      }

      .index-selector.selected {
        .drop-down-selector-container {
          background-color: #73e4cc;
          border: 2px solid #c4fff9;
          .current-choice {
            color: #165f59 !important;
          }

          .current-choice::before {
            content: url("../../assets/icons/svg/sidebar/active/vegetationIndexactive.svg");
            padding-top: 5px;
            padding-inline-end: 5px;
          }
        }
      }

      .ele-selected,
      .ele:hover {
        .current-choice::before {
          content: url("../../assets/icons/svg/VegetationIndex/elevHov.svg") !important;
          padding-top: 5px;
          padding-inline-end: 5px;
        }
      }
    }
  }

  .description {
    font-size: 12px;
    font-family: "nunito_semibold";
    color: #46aaa0;
    margin-bottom: -1px;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
    width: 100%; /* Ensure full width utilization */
    word-wrap: break-word; /* Break long words */
    overflow-wrap: break-word;
    white-space: normal; /* Allow text wrapping */
  }

  .status-cards {
    display: flex;
    gap: 8px;
    margin-bottom: 35px;
    margin-top: 15px;

    .status-card {
      flex: 1;
      /* Light blue background similar to the image */
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 115px;
      position: relative; /* Allows absolute positioning for the avatar */
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      //transition: border 0.3s ease, box-shadow 0.3s ease;

      &.irrigation {
        background: #1552a2;
        color: #c4fff9 !important;

        .status-avatar {
          background: #1552a2 0% 0% no-repeat padding-box;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 3px 6px #233b5c73;
          font-size: 14px;
          position: absolute; /* Positioned above the card */
          top: -20px; /* Pulls the avatar up */
        }

        &.selected {
          border: 2px solid #c4fff9; /* Highlight border for selected card */
          position: relative;

          /* Arrow that points to the element */
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 20px 0 20px; /* Triangle arrow */
            border-color: #1552a2 transparent transparent transparent;
            top: 100%; /* Position below the card */
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      &.moisture {
        background: #8da9cf;
        color: #02312c;

        .warning-label {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 12px; /* Increased size to accommodate the border */
          height: 12px; /* Increased size to accommodate the border */
          background-color: #ff5b33; /* Circle color */
          border-radius: 50%;
          border: 1px solid white; /* White border with increased width */
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
          z-index: 1; /* Ensure circle stays on top */
        }
        .status-avatar {
          background: #8da9cf 0% 0% no-repeat padding-box;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          box-shadow: 0px 3px 6px #233b5c73;
          position: absolute; /* Positioned above the card */
          top: -20px; /* Pulls the avatar up */
        }

        &.selected {
          border: 2px solid #c4fff9; /* Highlight border for selected card */
          // position: relative;

          /* Arrow that points to the element */
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 20px 0 20px; /* Triangle arrow */
            border-color: #8da9cf transparent transparent transparent;
            top: 100%; /* Position below the card */
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      &.growth {
        background: #7bc792;
        color: #02312c;

        .warning-label {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 12px; /* Increased size to accommodate the border */
          height: 12px; /* Increased size to accommodate the border */
          background-color: #ff5b33; /* Circle color */
          border-radius: 50%;
          border: 1px solid white; /* White border with increased width */
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
          z-index: 1; /* Ensure circle stays on top */
        }
        .status-avatar {
          background: #7bc792 0% 0% no-repeat padding-box;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          box-shadow: 0px 3px 6px #233b5c73;
          position: absolute; /* Positioned above the card */
          top: -20px; /* Pulls the avatar up */
        }

        &.selected {
          border: 2px solid #c4fff9; /* Highlight border for selected card */
          position: relative;

          /* Arrow that points to the element */
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 20px 0 20px; /* Triangle arrow */
            border-color: #7bc792 transparent transparent transparent;
            top: 100%; /* Position below the card */
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      /* Hover effect for all status cards */
      &:hover {
        border: 4px solid #6f9bd6; /* Default border color on hover */
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        /* Change the avatar border when hovering on the card */
        .status-avatar {
          border: 3px solid #46aaa0; /* Highlighted border color */
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
      }

      /* Optional: Different border colors for specific cards on hover */
      &.irrigation:hover {
        border: 2px solid #c4fff9;
        .status-avatar {
          border: 2px solid #c4fff9; /* Highlighted border color */
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
      }

      &.moisture:hover {
        border: 2px solid #c4fff9;

        .status-avatar {
          border: 2px solid #c4fff9; /* Highlighted border color */
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
      }

      &.growth:hover {
        border: 2px solid #c4fff9;
        .status-avatar {
          border: 3px solid #c4fff9; /* Highlighted border color */
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
      }

      .status-info {
        text-align: center;
        margin-top: 30px; /* Adds space to account for the avatar */
        display: flex;
        flex-direction: column;
        .value {
          font-size: 0.75rem;
          font-weight: bold !important;
          margin-top: -8px;
        }

        .label {
          top: 10px;
          font-size: 0.75rem;
          font-family: "nunito_semibold";
        }
        h4 {
          margin-top: -22px;
          margin-bottom: 18px;
          font-size: 13px;
          font-family: "nunito_semibold";
        }

        p {
          margin: 0;
          font-size: 0.75rem;
        }
      }
    }
  }
  .plant-condition {
    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
      margin-top: 10px;
      margin-bottom: -8px;
    }
    .h3 {
      font-size: 13px;
      color: #c4fff9;
    }
    .subtitle {
      color: #46aaa0;
      display: flex; /* Aligns the image and text horizontally */
      align-items: center; /* Vertically centers the image with the text */
      // padding-top: 1vh;
    }
    .subtitle-icon {
      width: 16px; /* Adjust the size of the image */
      height: 16px;
      margin-right: 8px; /* Adds spacing between the image and the text */
    }

    .plant-card {
      .plant-card-avatar {
        position: absolute;
        top: -2vh;
        right: 1vw;
        background: #165f59; /* Avatar background */
        width: 52px; /* Avatar size */
        height: 52px;
        border-radius: 50%; /* Circular shape */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6); /* Adds shadow */
        font-size: 14px; /* Adjust emoji/font size */
        z-index: 10; /* Ensure it appears above other elements */

        .value {
          font-size: 18px;
          font-weight: 600;
          margin-top: 3px;
        }

        .label {
          font-size: 10px;
          color: #c4fff9;
        }
      }
      .plant-card-avatar::after {
        content: "";
        position: absolute;
        top: 0vh;
        left: 50%;
        transform: translateX(-50%);
        width: 1.5vw;
        height: 1.5vh; /* Arrow height */
        background: #dbc65d; /* Arrow color */
        border-radius: 50%; /* Make edges rounded */
        clip-path: polygon(
          50% 100%,
          0% 0%,
          100% 0%
        ); /* Adjust for downward-facing arrow */
      }
      // background-color: #46AAA0;
      margin-top: 20px;
      border-radius: 10px;
      // box-shadow: 0px 0px 5px 5px #6f9bd6 !important;
      background: #165f59;
      position: relative;
      color: #c4fff9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .plant-image {
        margin-left: -8vw;
        margin-top: 2vh;
        padding: 15px 30px 20px 15px;
        width: 25vw; /* Adjust the size of the image */
        height: 25vh;
        // z-index: 100;
      }
      .label-pointer {
        height: 3px;
        background-color: #dbc65d;
        width: 6.5vw;
        margin-left: -9vw;
        top: -15.2vh;
        z-index: 100;
        margin-top: 0vh;
        transform: translateY(-50%);
        position: relative;
      }
      .pointer1 {
        height: 1px;
        background-color: #c4fff9;
        width: 3.5vw;
        margin-left: -2vw;
        top: -33.2vh;
        z-index: 100;
        margin-top: 0vh;
        transform: translateY(-50%);
        position: relative;
      }
      .pointer2 {
        height: 1px;
        background-color: #c4fff9;
        width: 3.5vw;
        margin-left: 1vw;
        top: -29.2vh;
        z-index: 100;
        margin-top: 0vh;
        transform: translateY(-50%);
        position: relative;
      }
      .pointer3 {
        height: 1px;
        background-color: #c4fff9;
        width: 3.5vw;
        margin-left: 0vw;
        top: -25.5vh;
        z-index: 100;
        margin-top: 0vh;
        transform: translateY(-50%);
        position: relative;
      }
      .label1 {
        opacity: 1 !important;
        padding: 4px;
        z-index: 100 !important;
        background-color: #7bc792;
        margin-left: 7.5vw;
        top: -35.2vh;
        border-radius: 3px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .text {
          // font-size: 12px;
          color: #02312c;
        }
      }
      .label2 {
        opacity: 1 !important;
        padding: 4px;
        z-index: 100 !important;
        background-color: #46aaa0;
        margin-left: 10vw;
        top: -32vh;
        position: relative;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        .text {
          color: #c4fff9;
          text-wrap-mode: nowrap;
          // font-size: 12px;
          margin-left: 2px;
        }
      }
      .label3 {
        opacity: 1 !important;
        z-index: 100 !important;
        padding: 4px;
        background-color: #dbc65d;
        margin-left: 9.5vw;
        top: -28.2vh;
        position: relative;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .text {
          color: #02312c;
          // font-size: 12px;
          text-wrap-mode: nowrap;
        }
      }
      .label3-growth {
        opacity: 1 !important;
        width: 9.5vw;
        z-index: 100 !important;
        height: 9vh;
        background-color: #dbc65d;
        margin-left: 12.5vw;
        top: -28.2vh;
        position: relative;
        border-radius: 3px;
        display: flex;
        padding: 5px;
        padding-bottom: 5px;
        flex-direction: column;
        align-items: left;
        justify-content: left;
        .text {
          color: #02312c;
          text-wrap-mode: nowrap;
        }
      }
      .plant-root-container {
        width: 6.5vw;
        height: 15vh;
        background-color: #46aaa0;
        opacity: 0.5;
        margin-left: -9vw;
        position: relative;
        top: -15.3vh;
      }

      .plant-description {
        margin-top: -29vh;
        padding: 10px;
        color: #c4fff9;
      }
      h4 {
        color: #c4fff9;
        align-self: self-start;
        //margin: 10px 10px 10px 10px;
        font-size: 13px;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-top: 20px;
      }
      .card-separator {
        height: 1px; /* Thickness of the line */
        background-color: #46aaa0; /* Color of the line */
        width: 90%; /* Full width inside the panel */
        position: absolute; /* Positioning inside the panel */
        margin-top: 2vh;
        transform: translateY(-50%); /* Centers perfectly */
        position: relative;
      }

      .plot-cards-plant-condition {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
        gap: 15px;
        .actual-card {
          width: 9vw;
          height: 10vh;
          border-radius: 10px;
          background-color: #02312c66;
          border: 0px;
        }
        .accumulated-card {
          width: 9vw;
          height: 10vh;
          border-radius: 10px;
          background-color: #02312c66;
          border: 0px;
        }
        .card-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%; /* Ensures the card-text fills the parent card height */
          text-align: center; /* Ensures text alignment for title/subtitle */
          .card-title {
            font-size: 18px;
            color: #c4fff9;
            font-weight: bolder;
            margin-bottom: 0;
          }
          .card-subtitle {
            font-size: 13px;
            color: #46aaa0;
          }
        }
      }
    }
  }
  .progress-bar-position-plot {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-progress-bar-irrigation {
    background-color: #1552a2 !important;
    transition: width 1.5s ease-in-out; /* Smooth transition for width */
    border-radius: 5px;
    margin: 2px;
  }
  .custom-progress-bar-irrigation-plot-view {
    background-color: #1552a2 !important;
    transition: width 1.5s ease-in-out; /* Smooth transition for width */
    border-radius: 5px;
    margin: 2px;
  }
  .custom-progress-bar-moisture {
    background-color: #8da9cf;
    transition: width 1.5s ease-in-out; /* Smooth transition for width */
    border-radius: 5px;
    color: #02312c;
    margin: 2px;
  }
  .custom-progress-bar-growth {
    background-color: #7bc792;
    transition: width 1.5s ease-in-out; /* Smooth transition for width */
    border-radius: 5px;
    color: #02312c;
    margin: 2px;
  }
  .progress-irrigation {
    background-color: #14524d;
    margin-top: 5px;
  }
  .progress-irrigation-plot-view {
    background-color: #14524d;
    margin-top: 18px;
  }
  .progress-moisture {
    background-color: #14524d;
    margin-top: 5px;
    // width:195px !important;
  }
  .progress-growth {
    background-color: #14524d;
    margin-top: 18px;
    // width:180px !important;
  }
  .irrigation-section {
    margin-bottom: 16px;
    // box-shadow: 0px 0px 5px 5px #6f9bd6 !important;
    background: #165f59;
    padding: 15px 20px;
    position: relative;
    color: #c4fff9;
    font-family: "nunito_semibold";

    .cards-container {
      display: flex;
      flex-direction: row; /* Align the cards side by side */
      justify-content: center; /* Center the cards horizontally */
      align-items: center; /* Center the cards vertically */
      gap: 15px; /* Space between the cards */
      width: 100%; /* Ensure cards container takes the full width */
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .water-needed-card {
      border-radius: 10px;
      background-color: #fdc47359;
      border: 1px solid #fdc4734d;
      padding: 7px;
      //margin-top: 1rem;
      min-width: 9vw;
      height: 6vh; /* Increased height of the cards */
      display: flex; /* Make sure the cards are flex containers */
      justify-content: center; /* Center any content inside the cards */
      align-items: center; /* Center any content vertically */
      flex-shrink: 0; /* Prevent shrinking of the cards */
      flex-grow: 0; /* Prevent growing beyond their initial size */
    }
    .water-applied-card {
      border-radius: 10px;
      background-color: #1552a259;
      border: 1px solid #1552a24d;
      padding: 7px;
      //margin-top: 1rem;
      min-width: 9vw;
      height: 6vh; /* Increased height of the cards */
      display: flex; /* Make sure the cards are flex containers */
      justify-content: center; /* Center any content inside the cards */
      align-items: center; /* Center any content vertically */
      flex-shrink: 0; /* Prevent shrinking of the cards */
      flex-grow: 0; /* Prevent growing beyond their initial size */
    }
    .card-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
    }

    .card-title {
      font-size: 12px;
      color: #c4fff9;
      font-weight: bolder;
    }

    .value {
      font-size: 13px;
      font-family: nunito_extraBold;
      margin: 0 5px;
    }

    .card-subtitle {
      font-size: 10px;
      color: #c4fff9;
    }
    .linear-scale {
      flex-grow: 1;
      height: 10px;
      margin-left: 5%;
      width: 20px;
      background: linear-gradient(
        to right,
        #00f,
        #0f0
      ); /* Example gradient for visual scale */
      margin: 0px 10px 10px 30px; /* Space between text and button */
    }

    h4 {
      font-size: 13px;
      margin-left: 0px;
      margin-bottom: 3px;
      font-family: "nunito_semibold";

      // padding-top: 20px;
    }
    p {
      margin: 10px 10px 10px 10px;
      font-size: 13px;
      color: #46aaa0;
    }
    .irrigation-avatar {
      position: absolute;
      top: -2vh;
      right: 1vw;
      background: #165f59; /* Avatar background */
      width: 3.8vw; /* Avatar size */
      height: 7vh;
      border-radius: 50%; /* Circular shape */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6); /* Adds shadow */
      font-size: 14px; /* Adjust emoji/font size */
      z-index: 10; /* Ensure it appears above other elements */

      .value {
        font-size: 25px;
        font-weight: bold;
      }

      .label {
        top: 10px;
        font-size: 13px;
      }
    }

    .plot-info {
      display: grid;
      margin-top: 0.5rem;
      .plots-button {
        background-color: transparent;
        border: none;
        color: #73e4cc; /* Optional: Default text color */
        cursor: pointer;
        text-decoration: none;
        font-size: 12px;
      }

      /* Underline on hover */
      .plots-button:hover {
        text-decoration: underline;
      }

      /* Remove focus outline or click effect */
      .plots-button:focus {
        outline: none;
        border: none;
      }

      /* Optional: Disable default button appearance after clicking */
      .plots-button:active {
        background: transparent;
        border: none;
      }
      //margin-left: 8px;
      .plot-text {
        display: flex;
        flex-direction: row; /* Arrange child elements in a row */
        align-items: center; /* Optional: Aligns items horizontally if needed */
      }

      .value {
        font-size: 12px;
        margin-right: 10px;
        //white-space: nowrap; /* Prevent text wrapping */
      }

      .label {
        font-size: 13px;
        margin-left: 5px; /* Optional: space between label and value */
      }

      .plot {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;

        span {
          font-size: 13px;
        }

        .create-task {
          display: flex;
          flex-direction: column; /* Stacks the icon and text vertically */
          align-items: center; /* Centers the icon and text horizontally */
          justify-content: center; /* Centers the content vertically */
          //margin-left: 20px;
          background-color: transparent; /* Button background color */
          color: #73e4cc; /* Text and icon color */
          border: none;
          border-radius: 8px; /* Rounded corners */
          font-family: Arial, sans-serif;
          font-size: 13px;
          cursor: pointer;
          width: 100px; /* Adjust width to your preference */
          height: 80px; /* Adjust height to your preference */
          //box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
          text-align: center;

          &:hover {
            background-color: #73e4cc59; /* Darker shade for hover effect */
            transform: translateY(-2px); /* Slight upward movement on hover */
          }

          &:active {
            transform: translateY(0); /* Reset movement when clicked */
          }

          .button-icon {
            width: 24px; /* Adjust icon width */
            height: 24px; /* Adjust icon height */
            margin-bottom: 10px; /* Adds spacing between icon and text */
          }

          .button-text {
            font-size: 12px; /* Adjust text size */
            line-height: 1.2; /* Line height for button text */
          }
        }
      }
    }
    // .no-plot-info{
    //   display: flex !important;
    //   flex-direction: column !important;
    //   justify-content: center !important;
    //   align-items: center !important;
    //   text-align: center !important;
    //   margin-left: 8px !important;
    //   height: 100% !important;

    //   .card-image {
    //     width: 100px;
    //     height: 100px;
    //     margin-bottom: 10px; /* Space between the image and text */
    //     img {
    //       width: 100%;
    //       height: 100%;
    //       object-fit: cover; /* Ensures the image scales properly */
    //     }
    //   }

    //   p {
    //     font-size: 14px;
    //     color: #46AAA0;
    //   }
    // }
  }

  .no-plot-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 8px;
    height: 100%;
    .card-image {
      width: 100px;
      height: 100px;
      margin-bottom: 10px; /* Space between the image and text */
      img {
        width: 100%;
        height: 100%;
        //object-fit: cover; /* Ensures the image scales properly */
      }
    }

    .text {
      font-size: 13px;
      color: #c4fff9;
    }
    .description {
      font-size: 12px;
      color: #46aaa0;
    }
  }

  .moisture-section {
    margin-bottom: 16px;
    // box-shadow: 0px 0px 5px 5px #6f9bd6 !important;
    background: #165f59;
    padding: 15px 20px;
    position: relative;
    color: #c4fff9;
    font-family: 'nunito_semibold';
 
    .linear-scale {
      flex-grow: 1;
      height: 10px;
      margin-left: 5%;
      width: 20px;
      background: linear-gradient(
        to right,
        #00f,
        #0f0
      ); /* Example gradient for visual scale */
      margin: 0px 10px 10px 30px; /* Space between text and button */
    }

    h4 {
      font-size: 13px;
      margin-bottom: 15px;
      margin-top: 8px;
    }
    p {
      margin-top: -7px;
      font-size: 12px;
      color: #46aaa0;
    }
    .moisture-avatar {
      position: absolute;
      top: -2vh;
      right: 1vw;
      background: #165f59; /* Avatar background */
      width: 3.8vw; /* Avatar size */
      height: 7vh;
      border-radius: 50%; /* Circular shape */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6); /* Adds shadow */
      font-size: 14px; /* Adjust emoji/font size */
      z-index: 10; /* Ensure it appears above other elements */

      .value {
        font-size: 25px;
        font-weight: bold;
      }

      .label {
        top: 10px;
        font-size: 14px;
      }
    }
    .plot-info {
      display: grid;
      .plots-button {
        background-color: transparent;
        border: none;
        color: #73e4cc; /* Optional: Default text color */
        cursor: pointer;
        text-decoration: none;
        font-size: 12px;
      }

      /* Underline on hover */
      .plots-button:hover {
        text-decoration: underline;
      }

      /* Remove focus outline or click effect */
      .plots-button:focus {
        outline: none;
        border: none;
      }

      /* Optional: Disable default button appearance after clicking */
      .plots-button:active {
        background: transparent;
        border: none;
      }
      //margin-left: 8px;
      .plot-text {
        display: flex;
        flex-direction: row; /* Arrange child elements in a row */
        align-items: center; /* Optional: Aligns items horizontally if needed */
      }

      .value {
        font-size: 12px;
        margin-right: 10px;

        //white-space: nowrap; /* Prevent text wrapping */
      }

      .label {
        font-size: 13px;

        margin-left: 5px; /* Optional: space between label and value */
      }

      .plot {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;

        span {
          font-size: 13px;
        }

        .create-task {
          display: flex;
          flex-direction: column; /* Stacks the icon and text vertically */
          align-items: center; /* Centers the icon and text horizontally */
          justify-content: center; /* Centers the content vertically */
          //margin-left: 20px;
          background-color: transparent; /* Button background color */
          color: #73e4cc; /* Text and icon color */
          border: none;
          border-radius: 8px; /* Rounded corners */
          font-family: Arial, sans-serif;
          font-size: 13px;
          cursor: pointer;
          width: 100px; /* Adjust width to your preference */
          height: 80px; /* Adjust height to your preference */
          //box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
          text-align: center;

          &:hover {
            background-color: #73e4cc59; /* Darker shade for hover effect */
            transform: translateY(-2px); /* Slight upward movement on hover */
          }

          &:active {
            transform: translateY(0); /* Reset movement when clicked */
          }

          .button-icon {
            width: 24px; /* Adjust icon width */
            height: 24px; /* Adjust icon height */
            margin-bottom: 10px; /* Adds spacing between icon and text */
          }

          .button-text {
            font-size: 12px; /* Adjust text size */
            line-height: 1.2; /* Line height for button text */
          }
        }
      }
    }
  }

  .card-avatar {
    position: absolute;
    top: -14px;
    right: 20px;
    background: #165f59; /* Avatar background */
    width: 52px; /* Avatar size */
    height: 52px;
    border-radius: 50%; /* Circular shape */
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6); /* Adds shadow */
    font-size: 14px; /* Adjust emoji/font size */
    z-index: 10; /* Ensure it appears above other elements */

    .value {
      font-size: 16px;
      font-weight: bold;
    }

    .label {
      font-size: 10px;
      color: #c4fff9;
    }
  }
  .growth-section {
    margin-bottom: 16px;
    // box-shadow: 0px 0px 5px 5px #6f9bd6 !important;
    background: #165f59;
    padding: 15px 20px;
    position: relative;
    color: #c4fff9;
    font-family: 'nunito_semibold';
    .linear-scale {
      flex-grow: 1;
      height: 10px;
      margin-left: 5%;
      width: 20px;
      background: linear-gradient(
        to right,
        #00f,
        #0f0
      ); /* Example gradient for visual scale */
      margin: 0px 10px 10px 30px; /* Space between text and button */
    }

    h4 {
      font-size: 13px;
      margin-bottom: 15px;
      margin-top: 8px;
    }
    p {
      margin-top: -7px;
      font-size: 12px;
      color: #46aaa0;
    }

    .plot-info {
      display: grid;
      .plots-button {
        background-color: transparent;
        border: none;
        color: #73e4cc; /* Optional: Default text color */
        cursor: pointer;
        text-decoration: none;
        font-size: 12px;
      }

      /* Underline on hover */
      .plots-button:hover {
        text-decoration: underline;
      }

      /* Remove focus outline or click effect */
      .plots-button:focus {
        outline: none;
        border: none;
      }

      /* Optional: Disable default button appearance after clicking */
      .plots-button:active {
        background: transparent;
        border: none;
      }
      //margin-left: 8px;
      .plot-text {
        display: flex;
        flex-direction: row; /* Arrange child elements in a row */
        align-items: center; /* Optional: Aligns items horizontally if needed */
      }

      .value {
        font-size: 12px;
        margin-right: 10px;

        //white-space: nowrap; /* Prevent text wrapping */
      }

      .label {
        font-size: 13px;

        margin-left: 5px; /* Optional: space between label and value */
      }

      .plot {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;

        span {
          font-size: 13px;
        }

        .create-task {
          display: flex;
          flex-direction: column; /* Stacks the icon and text vertically */
          align-items: center; /* Centers the icon and text horizontally */
          justify-content: center; /* Centers the content vertically */
          //margin-left: 20px;
          background-color: transparent; /* Button background color */
          color: #73e4cc; /* Text and icon color */
          border: none;
          border-radius: 8px; /* Rounded corners */
          font-family: Arial, sans-serif;
          font-size: 12px;
          cursor: pointer;
          width: 100px; /* Adjust width to your preference */
          height: 80px; /* Adjust height to your preference */
          //box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
          text-align: center;

          &:hover {
            background-color: #73e4cc59; /* Darker shade for hover effect */
            transform: translateY(-2px); /* Slight upward movement on hover */
          }

          &:active {
            transform: translateY(0); /* Reset movement when clicked */
          }

          .button-icon {
            width: 24px; /* Adjust icon width */
            height: 24px; /* Adjust icon height */
            margin-bottom: 10px; /* Adds spacing between icon and text */
          }

          .button-text {
            font-size: 12px; /* Adjust text size */
            line-height: 1.2; /* Line height for button text */
          }
        }
      }
    }
  }
  .irrigation-section-plot-view {
    margin-bottom: 16px;
    // box-shadow: 0px 0px 5px 5px #6f9bd6 !important;
    background: #165f59;
    padding-bottom: 5px;
    position: relative;
    color: #c4fff9;

    .separator {
      height: 1px; /* Thickness of the line */
      background-color: #46aaa0; /* Color of the line */
      width: 90%; /* Full width inside the panel */
      position: absolute; /* Positioning inside the panel */
      margin-top: 2vh;
      left: 20px;
      transform: translateY(-50%); /* Centers perfectly */
      position: relative;
    }
    .linear-scale {
      flex-grow: 1;
      height: 10px;
      margin-left: 5%;
      width: 20px;
      background: linear-gradient(
        to right,
        #00f,
        #0f0
      ); /* Example gradient for visual scale */
      margin: 0px 10px 10px 30px; /* Space between text and button */
    }

    h4 {
      margin: 10px 10px 10px 10px;
      font-size: 13px;
      margin-bottom: 5px;
      padding-top: 20px;
    }
    p {
      margin: -5px 10px 10px 10px;
      font-size: 13px;
      color: #46aaa0;
    }
    .plot-info {
      //margin-left: 8px;
      .plot-text {
        display: flex;
        flex-direction: row; /* Arrange child elements in a row */
        align-items: center; /* Optional: Aligns items horizontally if needed */
      }

      .value {
        // font-size: 10px;
        margin-right: 10px;

        //white-space: nowrap; /* Prevent text wrapping */
      }

      .label {
        font-size: 13px;

        margin-left: 5px; /* Optional: space between label and value */
      }

      .plot {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -15px;
        span {
          font-size: 12px;
        }
      }
    }
  }
  .plot-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
    margin-top: 1rem;
    background-color: #165f59;
    border-radius: 10px;
    grid-gap: 15px;
    gap: 15px;
    width: 110%;
    flex-direction: column;
  }
  .plot-cards-plant {
    display: flex;
    flex-direction: column; /* Stack title on top and cards in a row below */
    justify-content: center; /* Center content horizontally */
    align-items: flex-start; /* Center content vertically */
    padding: 20px;
    height: 280px;
    margin-top: 1rem;
    background-color: #165f59;
    border-radius: 10px;
    gap: 15px; /* Space between title and cards */
    width: 110%; /* Ensure full width available for the flex container */
    .plant-image {
      height: 154px;
      margin-left: 35px;
      // z-index: 100;
    }
    .plant-card-avatar {
      position: relative;  /* Add this */
      margin-left: 250px;
      margin-top: -57px;
      //top: -2vh;
      right: 1vw;
      background: #165f59;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6);
      font-size: 14px;
      z-index: 10;
      margin-bottom: 10px;
  }
  
  .plant-card-avatar .value {
      font-size: 15px;
      font-weight: 600;
      margin-top: 3px;
  }
  
  .plant-card-avatar .label {
      font-size: 10px;
      color: #c4fff9;
      font-family: "nunito_semibold";
  }
  
  .plant-card-avatar::after {
      content: "";
      position: absolute;  /* Changed from relative */
      bottom: 45px;      /* Position from bottom instead of using top */
      left: 50%;         /* Center horizontally */
      transform: translateX(-50%);
      width: 20.5px;
      height: 10.5px;
      background: #dbc65d;
      border-radius: 50%;
      clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
  }
    .plant-header{
      display: flex;
      flex-direction: row;
      gap: 5px;
      padding-top: 15px;
      margin-top: -50px;
      font-family: 'nunito_semibold';
      .panel-item {
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 -1;
      }

    .panel-text {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-wrap-mode: nowrap;
        // padding-left: 5px;
      }
      
      .icon {
        width: 30px;
        height: 60px;
        margin-bottom: 4px;
        margin-right: 5px;
        // margin-left: 20px;
      }
      .icon-date{
        width: 20px;
        height: 40px;
        margin-bottom: 4px;
        margin-right: 5px;
      }
      
      .value {
        font-size: 13px;
        //font-weight: bold;
        color: #C4FFF9;
      }
      
      .label {
        color: #46AAA0;
        text-transform: capitalize;
        // font-size: 14px;
      }
    }
    .label-pointer {
      height: 3px;
      background-color: #dbc65d;
      width: 88px;
      margin-left: 35px;
      /* margin-left: -9vw; */
      /* top: -15.2vh; */
      z-index: 100;
      margin-top: 240px;
      transform: translateY(-50%);
      position: absolute;
    }
    .pointer1 {
      height: 1px;
      background-color: #c4fff9;
      width: 63px;
      margin-left: 98px;
      margin-top: 1px;
      /* margin-left: -2vw; */
      /* top: -33.2vh; */
      z-index: 100;
      /* margin-top: 0vh; */
      transform: translateY(-50%);
      position: absolute;
    }

    .pointer2 {
      height: 1px;
      background-color: #c4fff9;
      width: 26px;
      margin-left: 123px;
      margin-top: 116px;
      /* margin-left: -2vw; */
      /* top: -33.2vh; */
      z-index: 100;
      /* margin-top: 0vh; */
      transform: translateY(-50%);
      position: absolute;
    }
    .pointer3 {
      height: 1px;
      background-color: #c4fff9;
      width: 35px;
      margin-left: 111px;
      margin-top: 237.5px;
      /* margin-left: -2vw; */
      /* top: -33.2vh; */
      z-index: 100;
      /* margin-top: 0vh; */
      transform: translateY(-50%);
      position: absolute;
    }
    .label1 {
      opacity: 1 !important;
      padding: 4px;
      z-index: 100 !important;
      background-color: #7bc792;
      margin-left: 162px;
      top: 378px;
      border-radius: 3px;
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text {
        text-wrap-mode: nowrap;
        font-size: 12px;
        color: #02312c;
        font-family: "nunito_semibold";
      }
    }
    .label2 {
      opacity: 1 !important;
      padding: 4px;
      z-index: 100 !important;
      background-color: #46aaa080;
      margin-left: 149px;
      top: 426px;
      border-radius: 3px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .text {
        text-wrap-mode: nowrap;
        font-size: 12px;
        color: #c4fff9;
        font-family: "nunito_semibold";
      }
    }
    .label3 {
      opacity: 1 !important;
      padding: 4px;
      z-index: 100 !important;
      background-color: #dbc65d;
      margin-left: 146px;
      top: 495px;
      border-radius: 3px;
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text {
        color: #02312c;
        font-size: 12px;
        text-wrap-mode: nowrap;
        font-family: "nunito_semibold";
      }
    }
    .label3-growth {
      opacity: 1 !important;
      padding: 4px;
      z-index: 100 !important;
      background-color: #7bc792;
      margin-left: 162px;
      top: 394px;
      border-radius: 3px;
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .text {
        color: #02312c;
        text-wrap-mode: nowrap;
        font-size: 12px;
      }
    }
    .plant-root-container {
      width: 88px;
      height: 99px;
      background-color: #46aaa0;
      opacity: 0.5;
      margin-top: 135px;
      margin-left: 35px;
      position: absolute;
    }
  }

  .title {
    font-size: 13px;
    color: #c4fff9;
    font-weight: bolder;
    text-align: flex-start; /* Center title text */
    padding: 1rem;
    padding-bottom: 0.3rem;
    align-self: flex-start;
    text-transform: capitalize;
    font-family: "nunito_extrabold";
  }
  .general-container {
    display: flex;
    flex-direction: column; /* Align the cards side by side */
    justify-content: center; /* Center the cards horizontally */
    align-items: center; /* Center the cards vertically */
    gap: 15px; /* Space between the cards */
    width: 100%; /* Ensure cards container takes the full width */
  }
  .cards-container {
    display: flex;
    flex-direction: row; /* Align the cards side by side */
    justify-content: center; /* Center the cards horizontally */
    align-items: center; /* Center the cards vertically */
    gap: 15px; /* Space between the cards */
    width: 100%; /* Ensure cards container takes the full width */
  }
  .blue-card {
    border-radius: 10px;
    background-color: #1552a2a6;
    border: 1px solid #84b9ff;
    padding: 5px;
    color: #c4fff9;
    max-width: 145px;
    min-width: 145px;
    height: 115px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex-shrink: 0;
    flex-grow: 0;
    font-family: 'nunito_semibold';
  }
  .light-blue-card {
    border-radius: 10px;
    background-color: #1552a259;
    border: 1px solid #84b9ffb3;
    padding: 5px;
    color: #c4fff9;
    max-width: 145px;
    min-width: 145px;
    height: 115px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex-shrink: 0;
    flex-grow: 0;
    font-family: 'nunito_semibold';
  }
  .bigger-yellow-card {
    border-radius: 10px;
    background-color: #fdc47326;
    border: 1px solid #fdc4734d;
    padding: 5px;
    color: #c4fff9;
    //margin-top: 1rem;
    min-width: 305px;
    height: 115px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex-shrink: 0;
    flex-grow: 0;
    font-family: 'nunito_semibold';
  }
  .green-card {
    border-radius: 10px;
    background-color: #7bc792a6;
    border: 1px solid #7bc792b3;
    padding: 5px;
    color: #c4fff9;
    max-width: 145px;
    min-width: 145px;
    height: 115px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex-shrink: 0;
    flex-grow: 0;
    font-family: 'nunito_semibold';
}

.card-header-u {
    align-self: flex-start; /* Align title and subtitle to the left */
    margin-bottom: 8px;
}

.card-title-u {
    font-weight: bold;
    font-size: 11px;
    margin-left: 3px;
}

.card-subtitle-u {
  font-size: 11px;
  color: #c4fff9;
  margin: -3px 0 0 0;
}

.card-value-u {
    margin: auto; /* Centers the value both horizontally and vertically in the remaining space */
    font-size: 24px;
    font-family: 'nunito_extrabold';
    font-weight: bold;
}
.card-capitalize{
  text-transform: capitalize;
}
.card-unit-u{
  margin: auto;
  font-size: 11px;
}
.date-container{
  display: flex;
  flex-direction: column;
  .card-date-text{
    margin: auto;
    font-size: 11px;
  }
  .card-date{
    margin: auto;
    font-size: 11px;
  }
}
.card-date{
  margin: auto;
  font-size: 11px;
}
  .light-green-card {
    border-radius: 10px;
    background-color: #46aaa0a6;
    border: 1px solid #7bc792b3;
    padding: 5px;
    color: #c4fff9;
    max-width: 145px;
    min-width: 145px;
    height: 115px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex-shrink: 0;
    flex-grow: 0;
    font-family: 'nunito_semibold';
  }
  .bigger-green-card {
    border-radius: 10px;
    background-color: #03403a;
    padding: 5px;
    color: #c4fff9;
    //margin-top: 1rem;
    min-width: 305px;
    height: 115px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex-shrink: 0;
    flex-grow: 0;
    font-family: 'nunito_semibold';
  }
  .delta-card-green {
    border-radius: 10px;
    background-color: rgba(123, 199, 146, 0.1);
    border: 1px solid rgba(123, 199, 146, 0.6);
    padding: 7px;
    //margin-top: 1rem;
    width: 305px;
    display: flex; /* Make sure the cards are flex containers */
    justify-content: flex-start; /* Center any content inside the cards */
    align-items: center; /* Center any content vertically */
    flex-shrink: 0; /* Prevent shrinking of the cards */
    flex-grow: 0; /* Prevent growing beyond their initial size */
    font-family: nunito_regular;
  }
  .text-style {
    font-size: 11px;
    color: #c4fff9;
    // font-family: 'nunito_semibold';
  }
  .delta-card-red {
    border-radius: 10px;
    background-color: rgba(255, 91, 51, 0.1);
    border: 1px solid rgba(255, 91, 51, 0.6);
    padding: 7px;
    //margin-top: 1rem;
    width: 305px;
    display: flex; /* Make sure the cards are flex containers */
    justify-content: flex-start; /* Center any content inside the cards */
    align-items: center; /* Center any content vertically */
    flex-shrink: 0; /* Prevent shrinking of the cards */
    flex-grow: 0; /* Prevent growing beyond their initial size */
    font-family: nunito_regular;
  }
  .card-text-delta {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top */
    align-items: flex-start; /* Align text to the left */
    height: 100%;
    text-align: left; /* Text alignment for children */
    word-wrap: break-word; /* Ensure text wraps */
  }
  .delta-img {
    width: 10px;
    margin-right: 4px;
  }
  .purple-card {
    border-radius: 10px;
    background-color: #c9a4cea6;
    border: 1px solid #c9a4ce;
    padding: 5px;
    color: #c4fff9;
    max-width: 145px;
    min-width: 145px;
    height: 90px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex-shrink: 0;
    flex-grow: 0;
    font-family: 'nunito_semibold';
  }
  .light-purple-card {
    border-radius: 10px;
    background-color: #c9a4ce59;
    border: 1px solid #c9a4ce;
    padding: 5px;
    color: #c4fff9;
    max-width: 145px;
    min-width: 145px;
    height: 90px;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    flex-shrink: 0;
    flex-grow: 0;
    font-family: 'nunito_semibold';
  }
  .span-delta {
    font-size: 10px;
    margin-top: 0rem;
    font-family: "nunito_extraBold";
    font-weight: "bold";
  }
  .productivity-card,
  .efficiency-card {
    border-radius: 10px;
    background-color: #03403af2;
    padding: 7px;
    //margin-top: 1rem;
    min-width: 9vw;
    height: 10.5vh; /* Increased height of the cards */
    display: flex; /* Make sure the cards are flex containers */
    justify-content: center; /* Center any content inside the cards */
    align-items: center; /* Center any content vertically */
    flex-shrink: 0; /* Prevent shrinking of the cards */
    flex-grow: 0; /* Prevent growing beyond their initial size */
  }

  .card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    font-family: "nunito_semibold";
  }
  .triangle-text {
    position: relative;
  }

  .triangle-text::after {
    content: "";
    position: absolute;
    right: 17.2rem; /* Adjust this value to position the triangle */
    top: 50%;
    transform: translateY(-50%);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #ff5b33; /* Color of the triangle */
  }

  .card-title {
    font-size: 12px;
    color: #c4fff9;
    font-weight: bolder;
  }

  .value {
    font-size: 24px;
    font-family: nunito_extraBold;
    margin: 0 5px;
  }

  .card-subtitle {
    font-size: 12px;
    color: #c4fff9;
    font-family: "nunito_semibold";
  }
  .moisture-section-plot-view {
    margin-bottom: 16px;
    // box-shadow: 0px 0px 5px 5px #6f9bd6 !important;
    background: #165f59;
    padding-bottom: 5px;
    position: relative;
    color: #c4fff9;

    .separator {
      height: 1px; /* Thickness of the line */
      background-color: #46aaa0; /* Color of the line */
      width: 90%; /* Full width inside the panel */
      position: absolute; /* Positioning inside the panel */
      margin-top: 2vh;
      left: 20px;
      transform: translateY(-50%); /* Centers perfectly */
      position: relative;
    }
    .linear-scale {
      flex-grow: 1;
      height: 10px;
      margin-left: 5%;
      width: 20px;
      background: linear-gradient(
        to right,
        #00f,
        #0f0
      ); /* Example gradient for visual scale */
      margin: 0px 10px 10px 30px; /* Space between text and button */
    }

    h4 {
      margin: 10px 10px 10px 10px;
      font-size: 13px;
      margin-bottom: 5px;
      padding-top: 20px;
    }
    p {
      margin: -5px 10px 10px 10px;
      font-size: 13px;
      color: #46aaa0;
    }
    .plot-info {
      display: flex;
      flex-direction: column; /* Stack rows vertically */
      gap: 1rem; /* Space between rows */
      padding: 1rem;
      box-sizing: border-box;
    }
    .row-container {
      display: flex;
      flex-direction: row; /* Two columns side by side */
      align-items: flex-start; /* Align elements to the top */
      gap: 2rem; /* Space between columns */
    }
    .moisture-column {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .moisture-level {
      width: 106px; /* Fix the width and prevent shrinking */
      height: 90px; /* Ensure the height matches the width for a perfect circle */
      border-radius: 50%; /* Circle shape */
      top: 1.2em;
      position: relative;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      background: #14524d;
      opacity: 0.4;

      // z-index: 100;
    }

    .moisture-level-value {
      position: absolute;
      top: 70%;
      left: 52%;
      transform: translate(-50%, -50%);
      color: #c4fff9;
      font-size: 20px;
      font-weight: bold;
      z-index: 100;
    }
    .upper-threshhold {
      position: absolute;
      top: 1.5rem; /* Position above the circle */
      left: 6.5rem;
      transform: translateX(-50%);
      font-size: 10px;
      color: #ff5b33;
      z-index: 100;
      text-wrap-mode: nowrap;
    }
    .lower-threshhold {
      position: absolute;
      bottom: 0.4rem; /* Position above the circle */
      left: 6.7rem;
      transform: translateX(-50%);
      font-size: 10px;
      color: #ff5b33;
      z-index: 100;
      text-wrap-mode: nowrap;
    }
    .dashed-line-with-padding-high {
      position: absolute;
      top: 2.5rem;
      left: 0;
      width: 100%;
      border-top: 2px dashed #ff5b33;
    }
    .dashed-line-with-padding-low {
      position: absolute;
      bottom: 0.3rem;
      left: 0;
      width: 100%;
      border-top: 2px dashed #ff5b33;
    }
    .moisture-description {
      font-size: 12px;
      line-height: 18px;
      // text-align: left;
      color: #fff;
    }

    h4 {
      margin-bottom: 0.5rem; /* Add spacing below the heading */
    }

    .plot-cards {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 10px;
      gap: 15px;
      .productivity-card {
        width: 9vw;
        height: 10vh;
        border-radius: 10px;
        background-color: #02312c66;
        border: 2px solid #84b9ff73;
      }
      .efficiency-card {
        width: 9vw;
        height: 10vh;
        border-radius: 10px;
        background-color: #02312c66;
        border: 2px solid #fdc47373;
      }
      .card-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%; /* Ensures the card-text fills the parent card height */
        text-align: center; /* Ensures text alignment for title/subtitle */
        .card-title {
          font-size: 18px;
          color: #c4fff9;
          font-weight: bolder;
        }
        .card-subtitle {
          font-size: 13px;
          color: #46aaa0;
        }
      }
    }
  }

  .growth-section-plot-view {
    margin-bottom: 16px;
    // box-shadow: 0px 0px 5px 5px #6f9bd6 !important;
    background: #165f59;
    padding-bottom: 5px;
    position: relative;
    color: #c4fff9;

    .separator {
      height: 1px; /* Thickness of the line */
      background-color: #46aaa0; /* Color of the line */
      width: 90%; /* Full width inside the panel */
      position: absolute; /* Positioning inside the panel */
      margin-top: 2vh;
      left: 20px;
      transform: translateY(-50%); /* Centers perfectly */
      position: relative;
    }
    .linear-scale {
      flex-grow: 1;
      height: 10px;
      margin-left: 5%;
      width: 20px;
      background: linear-gradient(
        to right,
        #00f,
        #0f0
      ); /* Example gradient for visual scale */
      margin: 0px 10px 10px 30px; /* Space between text and button */
    }

    h4 {
      margin: 10px 10px 10px 10px;
      font-size: 13px;
      margin-bottom: 5px;
      padding-top: 20px;
    }
    p {
      margin: -5px 10px 10px 10px;
      font-size: 13px;
      color: #46aaa0;
    }
    .plot-info {
      display: flex;
      flex-direction: column; /* Stack rows vertically */
      gap: 1rem; /* Space between rows */
      padding: 1rem;
      box-sizing: border-box;
    }

    .row-container {
      display: flex;
      flex-direction: row; /* Two columns side by side */
      align-items: flex-start; /* Align elements to the top */
      gap: 2rem; /* Space between columns */
    }

    .growth-column {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .growth-level {
      width: 106px; /* Fix the width and prevent shrinking */
      height: 90px; /* Ensure the height matches the width for a perfect circle */
      border-radius: 50%; /* Circle shape */
      top: 1.2em;
      background: #14524d;
      opacity: 0.4;
      position: relative;
    }

    .growth-level-value {
      position: absolute;
      top: 70%;
      left: 52%;
      transform: translate(-50%, -50%);
      color: #c4fff9;
      font-size: 20px;
      font-weight: bold;
      z-index: 100;
    }

    .upper-threshhold {
      position: absolute;
      top: 1.5rem; /* Position above the circle */
      left: 6.5rem;
      transform: translateX(-50%);
      font-size: 10px;
      color: #ff5b33;
      z-index: 100;
    }

    .lower-threshhold {
      position: absolute;
      bottom: 0.4rem; /* Position above the circle */
      left: 6.7rem;
      transform: translateX(-50%);
      font-size: 10px;
      color: #ff5b33;
      z-index: 100;
    }

    .dashed-line-with-padding-high {
      position: absolute;
      top: 2.5rem;
      left: 0;
      width: 100%;
      border-top: 2px dashed #ff5b33;
    }

    .dashed-line-with-padding-low {
      position: absolute;
      bottom: 0.3rem;
      left: 0;
      width: 100%;
      border-top: 2px dashed #ff5b33;
    }

    .growth-description {
      font-size: 12px;
      line-height: 18px;
      // text-align: left;
      color: #fff;
    }

    .accumulated-growth-card {
      height: 6vh;
      border: 1px solid #46aaa0;
      background-color: #02312c66;
      display: flex;
      flex-direction: row;
      justify-content: space-between; /* Space between text and value */
      align-items: center; /* Vertically center text and value */
      padding: 0 1rem; /* Add padding to the left and right */

      .text {
        color: #c4fff9;
        font-size: 12px; /* Ensure proper font size */
        flex: 1; /* Allow text to take up remaining space */
        text-align: left; /* Align text to the left */
      }

      .value {
        color: #c4fff9;
        font-size: 13px; /* Match font size */
        flex: 0; /* Do not grow */
        text-align: right; /* Align value to the right */
        text-wrap-mode: nowrap;
      }
    }

    h4 {
      margin-bottom: 0.5rem; /* Add spacing below the heading */
    }

    .plot-cards {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 10px;
      gap: 15px;
      .productivity-card {
        width: 9vw;
        height: 10vh;
        border-radius: 10px;
        background-color: #02312c66;
        border: 2px solid #84b9ff73;
      }
      .efficiency-card {
        width: 9vw;
        height: 10vh;
        border-radius: 10px;
        background-color: #02312c66;
        border: 2px solid #fdc47373;
      }
      .card-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%; /* Ensures the card-text fills the parent card height */
        text-align: center; /* Ensures text alignment for title/subtitle */
        .card-title {
          font-size: 18px;
          color: #c4fff9;
          font-weight: bolder;
        }
        .card-subtitle {
          font-size: 13px;
          color: #46aaa0;
        }
      }
    }
  }
  .crop-distribution {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Allows absolute positioning inside it */

    .crop-details {
      /* Position text container on left-center */
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%); /* Vertically center the text */
      font-size: 13px; /* Optional: Adjust font size as needed */

      p {
        margin: 4px 0;
      }
    }

    .distribution-graph {
      /* Move the graph to the right */
      text-align: center;
      position: absolute;
      right: 0vw;
      top: 15vh;
      transform: translateY(-50%); /* Vertically center the graph */

      p {
        margin: 4px 0;
        font-weight: bold;
      }
    }

    .legend-panel-crop-distribution {
      background: transparent;
      display: flex;
      flex-direction: column;
      opacity: 1;
      width: 10vw;
      height: 25vh;
      top: 3vh;
      padding: 10px 15px;
      border-radius: 10px;
      font: normal normal normal 14px/21px;
      position: relative; /* For internal positioning of elements */
      @media (max-width: 1200px) {
        width: 85%;
      }
      .section1,
      .section2 {
        display: flex;
        align-items: center; /* Vertically align box and text */
        background: transparent;
        opacity: 1;
        width: 190px;
        height: 30px;
        margin-bottom: 12px;
      }

      .section2 {
        margin-top: 22px;
      }

      .color-box {
        width: 20px;
        height: 20px;
        margin-right: 14px; /* Space between box and text */
        border: 0px solid #ffffff; /* Optional: Add a border around the box */
      }
      .legend-text {
        display: flex;
        flex-direction: column;
      }

      .irrigate {
        background-color: #7edfd5; /* Color for irrigate area */
      }

      .un-irrigate {
        background-color: #949494; /* Color for un-irrigate area */
      }
    }
  }
}



.threshhold-text-moisture {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  .value {
    font-size: 10px;
    font-weight: bold;
  }

  .label {
    top: 8px;
    font-size: 9px;
  }
}
.threshhold-text-growth {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  .value {
    font-size: 10px;
    font-weight: bold;
  }

  .label {
    top: 8px;
    font-size: 12px;
  }
}
.backFarmButton {
  height: 40px;
  width: 162px;
  background-color: #03403A;
  position: absolute;
  top: 10px;
  inset-inline-start: 20px;
  transition: all 0.2s ease-out;
  border-radius: 5px;
  border: none;
  padding: 0 15px;
  display: flex;
  align-items: center;
  gap: 30px;
  white-space: nowrap;
  outline: none;
  overflow: hidden;

  // Create pseudo-element for the background image
  &::before {
    content: '';
    position: absolute;
    left: 10px;
    width: 15px; // Adjust based on your icon size
    height: 15px; // Adjust based on your icon size
    background-image: url("../../assets/icons/svg/IrriWatch/backArrowLight.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  
  span {
    color: #73e4cc;
    margin-left: 25px;
    font-family: 'nunito_semibold';
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px #73E4CC;
  }

  &:hover {
    background-color: #73E4CC;

    &::before {
      background-image: url("../../assets/icons/svg/IrriWatch/backArrowDark.svg");
    }
    
    span {
      color: #03403A;
    }
  }
}

// RTL styles
[dir="rtl"] .backFarmButton {
  &::before {
    transform: scaleX(-1);
    right: 10px;
    left: auto;
    margin-left: 20px;
  }

}
[dir="rtl"] .backFarmButton span{
  margin-left: unset;
  margin-right: 20px;
}

.side-panel-Irriwatch .growth-section .plot-info .plot span {
  font-size: 13px !important;
}

@media(max-width: 768px) {
.side-panel-Irriwatch{
  width:22rem !important;
  height: 41rem !important;
}
.side-panel-Irriwatch .plot-cards-plant .plant-card-avatar{
  margin-left: 230px;
}
.backFarmButton::after {
  content: "";
  color: #73e4cc;
  margin-inline-start: 30px;
  opacity: 1;
  transform: translateX(0);
  transition: all 0.3s ease-out;
  width: 70px !important;
}
[dir="rtl"] .backFarmButton::after {
  content: "";
  margin-inline-start: 5px;
}
[dir="rtl"] .backFarmButton:hover{
  background-size: 50% !important;
} 
[dir=rtl] .backFarmButton::before{
  background-size: 100% !important;
  right: 12px;
}
.backFarmButton::before{
  left: 12px;
} 
.backFarmButton {
  width: 40px !important;
  height: 30px;
  background-size: 50%;
}
  .backFarmButton:hover{
    background-size: 50%;
  } 
  .backFarmButton span{
    display: none;
  }
  // [dir=rtl] .side-panel-Irriwatch .plot-cards-plant .plant-card-avatar::after{
  //   left: 45px !important; 
  // } 
}

[dir="rtl"] .side-panel-Irriwatch .crop-distribution .distribution-graph {
  right: unset;
  left: 0vw;
}
[dir="rtl"] .side-panel-Irriwatch .card-avatar {
  right: unset;
  left: 10px;
}
[dir="rtl"] .side-panel-Irriwatch .plant-condition .plant-card h4 {
  margin-right: 15px;
}
[dir="rtl"] .side-panel-Irriwatch .growth-section-plot-view .upper-threshhold {
  left: 0.3rem;
}

[dir="rtl"]
  .side-panel-Irriwatch
  .moisture-section-plot-view
  .upper-threshhold {
  left: 0.3rem;
}
[dir="rtl"] .side-panel-Irriwatch .growth-section-plot-view .lower-threshhold {
  left: 0.3rem;
}

[dir="rtl"]
  .side-panel-Irriwatch
  .moisture-section-plot-view
  .lower-threshhold {
  left: 0.3rem;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .plant-condition
  .plant-card
  .plant-card-avatar {
  left: 8px;
  right: unset;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .growth-section-plot-view
  .accumulated-growth-card
  .text {
  text-align: right;
}
[dir="rtl"] .side-panel-Irriwatch .plant-condition .plant-card .label2 {
  margin-right: 17px;
  text-wrap-mode: nowrap;
}
[dir="rtl"] .side-panel-Irriwatch .irrigation-section-plot-view .separator {
  left: 0px;
  right: 12px;
}

// [dir=rtl] .side-panel-Irriwatch .moisture-section-plot-view .dashed-line-with-padding-low{
//   //margin-left: unset;
//   left: 10.5em;
// }
// [dir=rtl] .side-panel-Irriwatch .moisture-section-plot-view .dashed-line-with-padding-high{
//  // margin-left: unset;
//   left: 25em;
//}

[dir="rtl"]
  .side-panel-Irriwatch
  .irrigation-section-plot-view
  .plot-cards
  .card-text
  .card-title {
  font-weight: 100;
  font-size: 12px;
}
[dir="rtl"] .side-panel-Irriwatch .progress-moisture {
  width: 170px !important;
}
[dir="rtl"].side-panel-Irriwatch
  .header
  .DatesContainer
  .date-time-content.farm-view {
  left: unset;
  right: 0rem !important;
  width: 20em;
}
[dir="rtl"] .side-panel-Irriwatch .triangle-text::after {
  right: unset;
  left: 16.2rem;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .header
  .DatesContainer
  .search-drop-down-list
  .form-multi-select-search {
  left: -59px !important;
}
[dir="rtl"] .side-panel-Irriwatch .header .date-time-content.farm-view {
  width: 20em !important;
  left: -7rem !important;
}
[dir="rtl"] .side-panel-Irriwatch .header .date-time-content.plot-view {
  left: -2rem !important;
  width: 20em !important;
}
[dir="rtl"] .side-panel-Irriwatch .delta-card {
  padding: unset;
  padding-right: 2rem;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .header
  .date-time-picker-wrapper
  .date-time-button.plot-view {
  margin-left: unset;
  margin-right: -3rem;
}
[dir="rtl"] .side-panel-Irriwatch.plot-view {
  left: -0.6rem;
}
[dir="rtl"] .side-panel-Irriwatch.farm-view {
  left: -0.6rem;
}
[dir="rtl"] .side-panel-Irriwatch .card-text-delta {
  text-align: right;
}
[dir="rtl"] .side-panel-Irriwatch .text-style {
  font-size: 11px;
  padding-right: 0.5rem;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .description-part
  .filter-buttons-container
  .dropdown-menu {
  min-width: 12rem !important;
  inset: 0px auto auto 15px !important;
}
// [dir="rtl"] .toggle-button .toggler-button-wrapper{
//   position: absolute;
//   left: 363px;
//   right: unset;
//   //top: 50px;
// }
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .pointer1{
  margin-right: 70px;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .label1 {
  margin-right: 132px;
  top: 358px;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .label2 {
  margin-right: 110px;
  top: 406px;
  //top: 390px;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .label1 .text {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .label2 .text {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .label3 .text {
  font-family: tajawal_medium;
}

[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .pointer2{
  margin-right: 84px;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .pointer3 {
  margin-right: 85px;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .label3 {
  margin-right: 120px;
  top: 476px;
  //top: 390px;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .plant-card-avatar {
  margin-right: 220px;
}

// [dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .plant-card-avatar::after{
//   left: 61px;
//   top: 198px;
// }
[dir="rtl"] .side-panel-Irriwatch .value {
  font-family: tajawal_medium;
}
[dir="rtl"].side-panel-Irriwatch .status-cards .status-card .status-info h4 {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .status-cards .status-card .status-info h4 {
  font-family: tajawal_medium;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .header
  .date-time-picker-wrapper
  .date-time-button {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .irrigation-section h4 {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .description {
  font-family: tajawal_medium;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .header
  .date-time-picker-wrapper
  .date-picker-container
  .nice-dates-navigation_previous:before {
  border-left: 8px solid #73e4cc !important;
  border-right: unset !important;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .header
  .date-time-picker-wrapper
  .date-picker-container
  .nice-dates-navigation_next:before {
  border-right: 8px solid #73e4cc !important;
  border-left: unset !important;
}
[dir="rtl"] .side-panel-Irriwatch .header {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .plot-cards-plant .plant-card-avatar .label {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .card-text {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .card-subtitle {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .title {
  font-family: tajawal_medium;
}
[dir="rtl"]
  .side-panel-Irriwatch
  .status-cards
  .status-card
  .status-info
  .label {
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .irrigation-section {
  font-family: tajawal_medium;
}
[dir="rtl"] .plotSelector-position .Map-Button-Container .List .Content{
  font-family: tajawal_medium;
}

[dir="rtl"] .plotSelector-position .Map-Button-Container .Button:hover .content{
  font-family: tajawal_medium;
} 
[dir="rtl"] .plotSelector-position .Map-Button-Container .Circle-Container p{
  font-family: tajawal_medium;
}
[dir="rtl"] .side-panel-Irriwatch .separator{
  left: 3px;
}
[dir="rtl"] .side-panel-Irriwatch .card-title-u{
  margin-left: unset;
  margin-right: 3px;
} 
